<template>
  <div class="SideMenu [ pt-8 ] w-full h-full bg-white text-primary-800">
    <header class="px-5 w-full flex items-center justify-between">
      <button @click="close">
        <svg-icon name="close" class="w-5 h-5" />
      </button>

      <AppLink>
        <svg-icon name="logo" height="21" width="122" />
      </AppLink>

      <button>
        <svg-icon name="cart" class="w-8 md:w-8 aspect-square" />
      </button>
    </header>
    <aside class="mt-8">
      <div class="px-5 flex gap-x-4 font-bold items-center">
        <svg-icon name="user" class="w-8 h-7 text-primary-700" />
        <AppLink :to="`${user?.email ? '/account' : '/login'}`">
          {{ user?.email ? `${user?.firstname} ${user?.lastname}` : t('loginOrRegister') }}
        </AppLink>
      </div>

      <hr class="mt-6 h-1 w-full bg-tertiary-600" />

      <ul class="px-5 divide-y-1">
        <li v-for="(brand, index) in brands" :key="brand?.id ?? index" class="py-6">
          <AppLink class="font-bold text-base" :to="`/brands/${brand.url_key}`">
            {{ brand?.name }}
          </AppLink>
        </li>

        <li class="py-6">
          <AppAccordion v-model="selectedItem" name="type">
            <template #summary>
              <strong>
                {{ t('shopBy', { name: t('categories') }) }}
              </strong>
            </template>
            <template #default>
              <ul class="mt-7 space-y-5">
                <li v-for="(child, index) in categories" :key="child.id ?? index" class="SubCategory">
                  <AppLink class="antialiased font-body tracking-wide text-primary-500" :to="`/${child.url_key}`">
                    {{ child.name }}
                  </AppLink>
                </li>
              </ul>
            </template>
          </AppAccordion>
        </li>

        <li class="py-6">
          <AppAccordion v-model="selectedItem" name="horsePower">
            <template #summary>
              <strong>
                {{ t('shopBy', { name: t('capacity') }) }}
              </strong>
            </template>
            <template #default>
              <ul v-if="acAttributes?.length" class="mt-7 space-y-5">
                <li v-for="(option, idx) in acAttributes" :key="idx" class="SubCategory">
                  <NuxtLink
                    class="antialiased font-body tracking-wide text-primary-500"
                    :to="{
                      path: `/${$i18n.locale}/products`,
                      query: {
                        'title-by': 'ac_horse_power',
                        ac_horse_power: option?.value,
                      },
                    }"
                    >{{ t('k', { value: option?.label }) }}
                  </NuxtLink>
                </li>
              </ul>
            </template>
          </AppAccordion>
        </li>
        <AppLink to="/deals" class="py-6 flex items-center text-red-700 exclude-ellipse font-medium">
          <svg-icon name="offer" width="28" height="30" class="mr-2" />
          {{ t('deals') }}
        </AppLink>
      </ul>

      <hr class="h-1 w-full bg-tertiary-600" />

      <ul class="px-5 divide-y-1">
        <li class="py-6 font-bold">
          <button @click="showShoppingGuide = true">
            {{ t('shoppingGuide') }}
          </button>
        </li>
        <li class="py-6 font-bold">
          <AppAccordion v-model="selectedItem" name="owner-support">
            <template #summary>
              <strong>
                {{ t('ownerSupport') }}
              </strong>
            </template>
            <template #default>
              <ul class="mt-7 flex flex-col gap-y-5">
                <li>
                  <AppLink to="/register-product-intro">{{ t('registerAProduct') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/book-appointment-intro">{{ t('bookAnAppointment') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/warranty-info">{{ t('warrantyInformation') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/search-manuals">{{ t('productManuals') }}</AppLink>
                </li>
              </ul>
            </template>
          </AppAccordion>
        </li>
        <li class="py-6 font-bold">
          <AppLink to="/commercial/commercial">{{ t('forBusiness') }}</AppLink>
        </li>
      </ul>

      <hr class="h-1 w-full bg-tertiary-600" />

      <ul class="px-5 divide-y-1">
        <li class="py-6 font-bold">
          <AppAccordion v-model="selectedItem" name="help-center">
            <template #summary>
              <strong>
                {{ t('helpCenter') }}
              </strong>
            </template>
            <template #default>
              <ul class="mt-7 flex flex-col gap-y-5">
                <li>
                  <AppLink to="/chat-support">{{ t('chatSupport') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/faqs">{{ t('faqs') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/contact">{{ t('contactUs') }}</AppLink>
                </li>
                <li>
                  <AppLink to="/careers">{{ t('careers') }}</AppLink>
                </li>
              </ul>
            </template>
          </AppAccordion>
        </li>
        <li class="py-6 font-bold flex items-center justify-between">
          <span>
            {{ t('callUs') }}
          </span>

          <span>
            {{ t('phoneNumber') }}
          </span>
        </li>
        <li class="py-6 font-bold flex items-center justify-between">
          <span>
            {{ t('changeLanguage') }}
          </span>
        </li>
      </ul>
    </aside>
    <DialogAcCalculator v-model="showShoppingGuide" />
  </div>
</template>
<script setup lang="ts">
const { toggleDefinedAlias } = useBottomSheet();
const { user } = useAuth();
const { t } = useI18n({
  useScope: 'local',
});
const selectedItem = ref('');
const data = await useMegaMenuCategories();

const brandsData = await useBrands();

const { attributes } = await useAttributes();

const acAttributes =
  attributes?.value?.find(attribute => attribute?.attribute_code === 'ac_horse_power')?.attribute_options ?? [];

const categories = data?.categories;

const brands = brandsData.brands.filter(brand => brand.is_shown_in_homepage === 1);

const close = () => {
  toggleDefinedAlias('sideMenu');
};

const showShoppingGuide = ref(false);

const route = useRoute();

watch(
  () => route.path,
  () => {
    close();
  },
  {
    deep: true,
  },
);
</script>

<style lang="postcss" scoped>
.SideMenu {
  max-height: 100vh;
  overflow: auto;
}
</style>
<i18n>
  {
    "en": {
      "loginOrRegister": "Login Or Register",
      "deals": "Deals",
      "shopBy": "Shop By {name}",
      "type": "Type",
      "capacity": "Capacity",
      "categories": "Categories",
      "shoppingGuide": "Shopping Guide",
      "ownerSupport": "Owner Support",
      "forBusiness": "For Business",
      "helpCenter": "Help Center",
      "callUs": "Call Us",
      "phoneNumber": "19111",
      "changeLanguage": "Change Language",
      "english": "عربي",
      "registerAProduct": "Register a Product",
      "bookAnAppointment": "Book an Appointment",
      "warrantyInformation": "Warranty Information",
      "productManuals": "Product Manuals",
      "chatSupport": "Chat Support",
      "faqs": "FAQs",
      "contactUs": "Contact Us",
      "k": "{value} HP",
      "careers": "Careers",
      "smartHome": "Smart Home"
    },
    "ar": {
      "loginOrRegister": "قم بالتسجيل او الدخول لحسابك",
      "deals": "العروض",
      "shopBy": "التصفية حسب {name}",
      "type": "النوع",
      "capacity": "القدرة",
      "categories": "الأقسام",
      "shoppingGuide": "دليل التسوق",
      "ownerSupport": "الدعم الخاص بالمُلاك",
      "forBusiness": "للأعمال",
      "helpCenter": "مركز المساعدة",
      "callUs": "اتصل بنا",
      "phoneNumber": "19111", 
      "changeLanguage": "تغيير اللغة",
      "english": "English",
      "registerAProduct": "تسجيل المنتج",
      "bookAnAppointment": "حجز موعد",
      "warrantyInformation": "معلومات الضمان",
      "productManuals": "دليل المنتج",
      "chatSupport": "الدعم عبر الدردشة",
      "faqs": "الأسئلة الشائعة",
      "contactUs": "تواصل معنا",
      "k": "{value} حصان",
      "careers": "الوظائف",
      "smartHome": "المنزل الذكي"
    }
  }
</i18n>
